import React from "react";
import "./TopNavbar.scss";

export class TopNavbar extends React.Component<any, any> {
    render() {
        return (<div className="top-navbar">
            <img className="logo" src="/szczecinek-logo.jpeg" style={{ height: "80%" }} />
            <img className="logo" src="/bo-logo.jfif" style={{ height: "80%" }} />
        </div>)
    }
}